<template>
  <section class="bg-secondary-dark">
    <div class="container__fullwidth">
      <div v-if="Object.keys(podcasts.basic || {}).length" class="lg:pl-16 pb-8 lg:pb-16">
        <Heading v-if="podcasts.basic?.heading" :text="podcasts.basic.heading" />
        <Subheading v-if="podcasts.basic?.subheading">
          {{ podcasts.basic.subheading }}
        </Subheading>
      </div>
      <PodcastWrapper :podcasts="cleanPodcasts" />
    </div>
  </section>
</template>
<script setup lang="ts">
const podcasts = usePodcasts()
const cleanPodcasts = computed(() => {
  const { basic, ...rest } = podcasts.value
  return rest
})
</script>
