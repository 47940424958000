<template>
  <article class="grid lg:grid-cols-3 grid-flow-row lg:grid-flow-col auto-cols-fr gap-5 lg:gap-0">
    <div class="grid custom-grid lg:grid-flow-col lg:auto-rows-min lg:auto-cols-auto gap-x-5 gap-y-4 lg:col-span-2 items-center lg:items-start">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-14 h-14 bg-primary rounded-full p-4 text-secondary-light hover:cursor-pointer select-none lg:row-span-2"
        @click="isPlaying = !isPlaying"
      >
        <path
          v-if="isPlaying"
          fill-rule="evenodd"
          d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z"
          clip-rule="evenodd"
        />
        <path
          v-else
          fill-rule="evenodd"
          d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
          clip-rule="evenodd"
        />
      </svg>
      <Heading :size="3" :text="episode.title" :heading-class="`text-xl lg:text-2xl`" class="lg:mt-2" />
      <p class="text-grey-light text-sm lg:text-base leading-7 col-span-2 lg:col-span-1">
        {{ episode.description }}
      </p>
    </div>
    <div v-if="podcast.spotifyUrl || podcast.appleUrl || podcast.googleUrl" class="grid lg:grid-rows-2 gap-y-4">
      <div class="flex items-center lg:justify-end gap-5 lg:gap-7 xl:gap-10">
        <p class="text-grey-light text-sm lg:text-base">Počúvaj&nbsp;na:</p>
        <figure class="grid grid-flow-col auto-cols-auto gap-4 xl:gap-6">
          <NuxtLink
            v-if="podcast.spotifyUrl"
            :to="podcast.spotifyUrl"
            target="_blank"
            class="inline-flex items-center"
            @mouseenter="animation($event, 'pulse')"
            ><img src="~/assets/images/spotify.svg" alt="Spotify logo" class="w-12 h-12 lg:w-14 lg:h-14"
          /></NuxtLink>
          <NuxtLink
            v-if="podcast.appleUrl"
            :to="podcast.appleUrl"
            target="_blank"
            class="inline-flex items-center"
            @mouseenter="animation($event, 'pulse')"
            ><img src="~/assets/images/apple.svg" alt="Apple Podcasts logo" class="w-12 h-12 lg:w-14 lg:h-14"
          /></NuxtLink>
          <NuxtLink
            v-if="podcast.googleUrl"
            :to="podcast.googleUrl"
            target="_blank"
            class="inline-flex items-center"
            @mouseenter="animation($event, 'pulse')"
            ><img src="~/assets/images/google.svg" alt="Google Podcasts logo" class="w-12 h-12 lg:w-14 lg:h-14"
          /></NuxtLink>
        </figure>
      </div>
    </div>
  </article>
</template>
<script setup lang="ts">
const animation = useAnimation()
const currentPodcastPlaying = useCurrentPodcastPlaying()
const props = defineProps({
  podcast: {
    type: Object,
    required: true,
  },
  episode: {
    type: Object,
    required: true,
  },
})

const audio = ref<HTMLAudioElement | null>(null)
const isPlaying = ref(false)

watch(isPlaying, (value) => {
  if (value) {
    if (currentPodcastPlaying.value) {
      currentPodcastPlaying.value?.pause()
      currentPodcastPlaying.value = ''
    }
    audio.value?.play()
    currentPodcastPlaying.value = audio.value
  } else {
    if (currentPodcastPlaying.value && !audio.value?.paused) {
      audio.value?.pause()
      currentPodcastPlaying.value = ''
    }
  }
})

watch(currentPodcastPlaying, (value, oldValue) => {
  if (oldValue === audio.value) {
    isPlaying.value = false
  }
})

watch(
  () => audio.value?.ended,
  (value) => {
    if (value) {
      currentPodcastPlaying.value = ''
      isPlaying.value = false
    }
  },
)

onMounted(() => {
  audio.value = new Audio(props.episode.audioUrl)
})
</script>
<style scoped>
.custom-grid {
  grid-template-columns: min-content 1fr;
}

@media screen(lg) {
  .custom-grid {
    @apply grid-cols-none;
  }
}
</style>
